import { default as account_45overviewx8Aak4uA61Meta } from "/opt/render/project/src/apps/portal/src/pages/dashboard/account-overview.vue?macro=true";
import { default as account_45setupZL2CuKDGveMeta } from "/opt/render/project/src/apps/portal/src/pages/dashboard/account-setup.vue?macro=true";
import { default as integration_45instructions1vEFTox6heMeta } from "/opt/render/project/src/apps/portal/src/pages/dashboard/integration-instructions.vue?macro=true";
import { default as indexiQ8TV3k90PMeta } from "/opt/render/project/src/apps/portal/src/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordCMUUoC4k1sMeta } from "/opt/render/project/src/apps/portal/src/pages/forgot-password.vue?macro=true";
import { default as login11uMseefUpMeta } from "/opt/render/project/src/apps/portal/src/pages/login.vue?macro=true";
import { default as redirectsPbyNijKy39Meta } from "/opt/render/project/src/apps/portal/src/pages/redirects.vue?macro=true";
import { default as register4H1AMh45gcMeta } from "/opt/render/project/src/apps/portal/src/pages/register.vue?macro=true";
import { default as reset_45passwordKyoCWsrzDAMeta } from "/opt/render/project/src/apps/portal/src/pages/reset-password.vue?macro=true";
import { default as component_45stub2XANr8MkyNMeta } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: account_45overviewx8Aak4uA61Meta?.name ?? "account-overview",
    path: account_45overviewx8Aak4uA61Meta?.path ?? "/dashboard/account-overview",
    meta: account_45overviewx8Aak4uA61Meta || {},
    alias: account_45overviewx8Aak4uA61Meta?.alias || [],
    redirect: account_45overviewx8Aak4uA61Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/portal/src/pages/dashboard/account-overview.vue").then(m => m.default || m)
  },
  {
    name: account_45setupZL2CuKDGveMeta?.name ?? "account-setup",
    path: account_45setupZL2CuKDGveMeta?.path ?? "/dashboard/account-setup",
    meta: account_45setupZL2CuKDGveMeta || {},
    alias: account_45setupZL2CuKDGveMeta?.alias || [],
    redirect: account_45setupZL2CuKDGveMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/portal/src/pages/dashboard/account-setup.vue").then(m => m.default || m)
  },
  {
    name: indexiQ8TV3k90PMeta?.name ?? "dashboard",
    path: indexiQ8TV3k90PMeta?.path ?? "/dashboard",
    meta: indexiQ8TV3k90PMeta || {},
    alias: indexiQ8TV3k90PMeta?.alias || [],
    redirect: indexiQ8TV3k90PMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/portal/src/pages/dashboard/index.vue").then(m => m.default || m),
    children: [
  {
    name: integration_45instructions1vEFTox6heMeta?.name ?? "integration-instructions",
    path: integration_45instructions1vEFTox6heMeta?.path ?? "integration-instructions",
    meta: integration_45instructions1vEFTox6heMeta || {},
    alias: integration_45instructions1vEFTox6heMeta?.alias || [],
    redirect: integration_45instructions1vEFTox6heMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/portal/src/pages/dashboard/integration-instructions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: forgot_45passwordCMUUoC4k1sMeta?.name ?? "forgot-password",
    path: forgot_45passwordCMUUoC4k1sMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordCMUUoC4k1sMeta || {},
    alias: forgot_45passwordCMUUoC4k1sMeta?.alias || [],
    redirect: forgot_45passwordCMUUoC4k1sMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/portal/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: login11uMseefUpMeta?.name ?? "login",
    path: login11uMseefUpMeta?.path ?? "/login",
    meta: login11uMseefUpMeta || {},
    alias: login11uMseefUpMeta?.alias || [],
    redirect: login11uMseefUpMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/portal/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: redirectsPbyNijKy39Meta?.name ?? "redirects",
    path: redirectsPbyNijKy39Meta?.path ?? "/redirects",
    meta: redirectsPbyNijKy39Meta || {},
    alias: redirectsPbyNijKy39Meta?.alias || [],
    redirect: redirectsPbyNijKy39Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/portal/src/pages/redirects.vue").then(m => m.default || m)
  },
  {
    name: register4H1AMh45gcMeta?.name ?? "register",
    path: register4H1AMh45gcMeta?.path ?? "/register",
    meta: register4H1AMh45gcMeta || {},
    alias: register4H1AMh45gcMeta?.alias || [],
    redirect: register4H1AMh45gcMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/portal/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordKyoCWsrzDAMeta?.name ?? "reset-password",
    path: reset_45passwordKyoCWsrzDAMeta?.path ?? "/reset-password",
    meta: reset_45passwordKyoCWsrzDAMeta || {},
    alias: reset_45passwordKyoCWsrzDAMeta?.alias || [],
    redirect: reset_45passwordKyoCWsrzDAMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/portal/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: component_45stub2XANr8MkyNMeta?.name ?? undefined,
    path: component_45stub2XANr8MkyNMeta?.path ?? "/",
    meta: component_45stub2XANr8MkyNMeta || {},
    alias: component_45stub2XANr8MkyNMeta?.alias || [],
    redirect: component_45stub2XANr8MkyNMeta?.redirect,
    component: () => import("/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]